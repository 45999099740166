import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';

import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import {DialogModule} from "./components/dialog/dialog.module";
import { TableModule } from './components/table/table.module';
import { ImageFieldComponent } from './components/image-field/image-field.component';
import { RatingBarComponent } from './components/rating-bar/rating-bar.component';
import { CommonModule } from '@angular/common';
import { SequencialDateFilterComponent } from './components/sequencial-date-filter/sequencial-date-filter.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { SearchModule } from './components/search/search.module';
import { DaySelectorFieldComponent } from './components/day-selector-field/day-selector-field.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    imports: [
        CommonModule,
        VerticalLayout1Module,
        FuseSharedModule,

        HorizontalLayout1Module,

        DialogModule,
        
        TableModule,

        MatIconModule,
        MatButtonModule,
        SearchModule
    ],
    exports: [
        MatTableModule,
        MatIconModule,
        MatButtonModule,

        VerticalLayout1Module,

        HorizontalLayout1Module,

        DialogModule,

        TableModule,

        ImageFieldComponent,

        RatingBarComponent,

        SequencialDateFilterComponent,
        SearchModule,
        DaySelectorFieldComponent
    ],
    declarations: [ImageFieldComponent, RatingBarComponent, SequencialDateFilterComponent, DaySelectorFieldComponent]
})
export class LayoutModule
{
}
