import { Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { UnsubscribeComponent } from 'app/main/pages/unsubscribe.component';
import { Call, User } from 'app/entities';
import { AuthService } from 'app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'app/services/alert.service';
import { Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { BootController } from 'app/services/boot-control.service';
import { CallService } from 'app/main/pages/accompaniments/call.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    navigation: any;
    pendingCalls: number;
    user: User;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _callService: CallService,
        private _authService: AuthService,
        public _matDialog: MatDialog,
        private ngZone: NgZone,
        private _alertService: AlertService,
        private _router: Router
    ) {
        super();
        this._callService.findAll({}).subscribe(res => {
            this.pendingCalls = res.data.filter(call => call.status === 4).length
        })
        this.navigation = navigation;
    }

    ngOnInit(): void {

        this._authService.currentUser$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(user => this.user = user);

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    logout() {
        let dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
          });
      
          dialog.componentInstance.confirmMessage = 'Deseja efetuar logout?';
      
          dialog.afterClosed().subscribe(result => {
            if(result) {
                this._authService.logout().subscribe(result => {
                    this._alertService.success("Logout efetuado com sucesso.", true)
                    this.ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
                    setTimeout(() => {
                        this._router.navigateByUrl('/auth/login')
                    }, 200)
                }, err => {
                    this._alertService.error(err)
                })
            }
            dialog = null
          })
    }

    profile() {
        this._router.navigate(['users', this.user.id])
    }
}
