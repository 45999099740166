export const environment = {
    local: false,
    production: false,
    hmr: false,

    appName: `Ciclo Verde [[ DEVELOPMENT ]]`,

    wsPort: 2000,
    wsHost: "",
    wsAppId: "",
    apiHost: "https://cicloverde-api-pcp.grupoifb.com.br",
    baseUrl: "https://cicloverde-api-pcp.grupoifb.com.br",
    apiUrl: "https://cicloverde-api-pcp.grupoifb.com.br/api",
    storageUrl: "https://cicloverde-api-pcp.grupoifb.com.br/storage",
    googleMapsKey: "AIzaSyDKay9VAjmvGXNNcNQt5oCNyrExq9z2Qc8",
};
