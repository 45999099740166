import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'barComponentnumberFormat'})
export class barComponentnumberFormatPipePipe implements PipeTransform
{
    transform(value: any, suffix: string): string
    {   
        if (typeof value === 'string') {
            value = parseFloat(value);
        }

        if (typeof value === 'number' && !isNaN(value)) {
            let number = value < 0 ? 0 : value;
            return `${number.toFixed(2)} ${suffix}`;
        } else {
            console.error('Invalid non-numeric value passed to barComponentnumberFormatPipe:', value);
            return value + ' ' + suffix;
        }
    }
}
